<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="mediaResources"
      :loading="loading"
      :options.sync="options"
      :server-items-length="getMediaResourcesCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
    >
      <template v-slot:top>
        <div class="d-flex mb-4 justify-end align-start">
          <v-text-field
            class="shrink mr-10 mt-0 pt-0"
            v-model="searchText"
            append-icon="mdi-magnify"
            hide-details
            label="Пошук"
            single-line
          ></v-text-field>
          <v-dialog v-model="dialog" max-width="400" @click:outside="close">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small v-bind="attrs" v-on="on"> Додати новий банер </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row v-if="editedItem.id" class="my-1">
                    <span class="mr-1">ID:</span>
                    <span class="font-weight-bold">{{ editedItem.id }}</span>
                  </v-row>
                  <v-row>
                    <v-select
                      v-model="editedItem.params.promotion_type"
                      label="Тип акції"
                      :items="promotionTypes"
                      :disabled="editedIndex !== -1"
                      :error-messages="inputErrors('promotion_type')"
                      item-value="value"
                      item-text="name"
                      @blur="$v.editedItem.params.promotion_type.$touch()"
                      @input="$v.editedItem.params.promotion_type.$touch()"
                    />
                  </v-row>
                  <v-row>
                    <v-select
                      v-model="editedItem.params.banner_type"
                      label="Тип банера"
                      :items="bannersTypesDictionary"
                      :disabled="editedIndex !== -1"
                      :error-messages="inputErrors('banner_type')"
                      item-value="value"
                      item-text="name"
                      @blur="$v.editedItem.params.banner_type.$touch()"
                      @input="$v.editedItem.params.banner_type.$touch()"
                    />
                  </v-row>
                  <v-row>
                    <v-file-input
                      v-model="file"
                      @change="loadImage"
                      :rules="rules"
                      :disabled="editedItem.params.banner_type === null"
                      label="Додати банер"
                      truncate-length="30"
                      prepend-icon="mdi-camera"
                      show-size
                    ></v-file-input>
                  </v-row>
                  <v-row>
                    <p
                      class="v-messages error--text"
                      v-if="$v.editedItem.file_data.$dirty && $v.editedItem.file_data.required === false"
                    >
                      Обовʼязкове поле
                    </p>
                    <p class="text-sm-caption my-0">Рекомендовані параметри: формат png, jpeg.</p>
                    <p class="text-sm-caption my-0">Для міні банера 327*80 пікселів</p>
                    <p class="text-sm-caption my-0">Для максі банера 375*260 пікселів</p>
                  </v-row>
                  <v-row>
                    <v-img v-if="imageSource" :src="imageSource" alt="Превʼю банера" />
                  </v-row>
                  <v-row v-if="canMakeGlobal">
                    <v-checkbox v-model="isGlobal" label="Глобальний банер" />
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="save">Так</v-btn>
                <v-btn text @click="close">Нi</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <AskDialog
            message="Увага!"
            sub-message="Ви бажаєте видалити цей банер?"
            :is-opened="dialogDelete"
            @yes="deleteBannerConfirm"
            @no="closeDelete"
          />
        </div>
      </template>
      <template v-slot:header.params.promotion_type="{ header }">
        <TableTitleFilter
          :options="promotionTypes"
          :title-text="header.text"
          item-value="value"
          item-text="name"
          @select="(selected) => (selectedPromotionTypes = selected)"
          @ok="applyFilter('params.promotion_type', selectedPromotionTypes, false)"
        />
      </template>
      <template v-slot:header.params.banner_type="{ header }">
        <TableTitleFilter
          :options="bannersTypesDictionary"
          :title-text="header.text"
          item-value="value"
          item-text="name"
          @select="(selected) => (selectedBannerTypes = selected)"
          @ok="applyFilter('params.banner_type', selectedBannerTypes, false)"
        />
      </template>
      <template v-slot:item.params.promotion_type="{ item }">{{ getPromType(item.params.promotion_type) }}</template>
      <template v-slot:item.params.banner_type="{ item }">{{ getBannerType(item.params.banner_type) }}</template>
      <template v-slot:item.file_name="{ item }">
        <v-img
          :src="`${baseURL}/uploads/media/${item.file_name}`"
          alt="Банер"
          :max-width="item.params.banner_type === 1 ? 94 : 164"
          :max-height="item.params.banner_type === 1 ? 65 : 40"
        />
      </template>
      <template v-slot:item.actions="{ item }">
        <TableActions no-view-button @edit="editItem(item)" @delete="deleteItem(item)" />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { cloneDeep, omit } from 'lodash'
import { BANNER_SCHEMA } from '@/const/apiSchemas'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import TableActions from '@/components/common/forms/TableActions'
import sortUtils from '@/mixins/sortUtils'
import filterUtils from '@/mixins/filterUtils'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter.vue'
import searchUtils from '@/mixins/searchUtils'

export default {
  name: 'PromotionsBannersSettings',

  components: {
    TableTitleFilter,
    TableActions,
    AskDialog: () => import('@/components/dialogs/AskDialog'),
  },

  mixins: [validationMixin, sortUtils, filterUtils, searchUtils],

  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API_URL,
      headers: [
        { text: 'ID', align: 'left', sortable: true, value: 'id' },
        { text: 'Тип акції', align: 'left', sortable: false, value: 'params.promotion_type' },
        { text: 'Тип банера', align: 'left', sortable: false, value: 'params.banner_type' },
        { text: 'Банер', align: 'left', sortable: false, value: 'file_name' },
        { text: 'Дія', value: 'actions', sortable: false, width: '125px', align: 'center' },
      ],
      loading: false,
      payload: null,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
      },
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      clickedBannerId: null,
      editedItem: null,
      imageSource: null,
      selectedPromotionTypes: [],
      selectedBannerTypes: [],
      searchText: null,
      searchFields: ['id'],
      rules: [(value) => !value || value.size < 2000000 || 'Розмір файлу не повинен перевищувати 2 Мб!'],
      file: null,
      isGlobal: false,
    }
  },

  validations() {
    return {
      editedItem: {
        file_data: !this.editedItem.id ? { required } : false,
        params: {
          promotion_type: { required },
          banner_type: { required },
        },
      },
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    options: {
      handler(val) {
        this.payload = {
          ...this.payload,
          page: val.page,
          limit: val.itemsPerPage,
          sort: this.sortObject,
        }
        this.paginateTo()
      },
      deep: true,
    },

    isGlobal(val) {
      if (val) {
        this.editedItem.company = null
      } else {
        this.editedItem.company = { id: this.currentCompanyId }
      }
    },
  },

  computed: {
    ...mapState('media', ['mediaResources', 'mediaResourcesMeta', 'mediaResource']),
    ...mapState('dictionaries', ['companyPromotionsDictionary', 'globalPromotionsDictionary', 'bannersTypesDictionary']),
    ...mapState('profile', ['userRoles']),
    ...mapGetters('media', ['getMediaResourcesCount']),

    currentCompanyId() {
      return this.$route.params.id
    },

    formTitle() {
      return `${this.editedIndex === -1 ? 'Додавання нового' : 'Редагування'} банеру`
    },

    promotionTypes() {
      return [...this.companyPromotionsDictionary, ...this.globalPromotionsDictionary].sort((a, b) => a.value - b.value)
    },

    canMakeGlobal() {
      return this.userRoles.includes('ROLE_ADMIN') || this.userRoles.includes('ROLE_SYSTEM_TECHNICAL_SUPPORT')
    },
  },

  methods: {
    ...mapActions('media', [
      'loadMediaResources',
      'createMediaResource',
      'loadSelectedMediaResource',
      'updateSelectedMediaResource',
      'deleteSelectedMediaResource',
    ]),

    async initialize() {
      this.reset()
      this.payload = {
        companyId: this.currentCompanyId,
      }
      this.filterObject = {
        params: {
          entity_type: 0,
        },
      }
    },

    async paginateTo() {
      this.payload = {
        ...this.payload,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        search: this.searchObject,
        ...this.filterObject,
      }
      this.loading = true
      try {
        await this.loadMediaResources(this.payload)
      } finally {
        this.loading = false
      }
    },

    inputErrors(fieldName) {
      const errors = []
      if (!this.$v.editedItem.params[fieldName].$dirty) return errors
      this.$v.editedItem.params[fieldName].required === false && errors.push('Обовʼязкове поле')
      return errors
    },

    getPromType(promotion) {
      return this.promotionTypes.find((item) => item.value === promotion)?.name || ''
    },

    getBannerType(bannerType) {
      return this.bannersTypesDictionary.find((item) => item.value === bannerType)?.name || ''
    },

    loadImage() {
      const reader = new FileReader()
      reader.onload = (event) => {
        const arrayBuffer = event.target.result
        const uint8Array = new Uint8Array(arrayBuffer)
        let binaryString = ''
        for (let i = 0; i < uint8Array.length; i++) {
          binaryString += String.fromCharCode(uint8Array[i])
        }
        const base64String = btoa(binaryString)
        this.editedItem.file_data = base64String
        this.imageSource = `data:image/jpeg;base64,${base64String}`
      }
      reader.readAsArrayBuffer(this.file)
    },

    reset() {
      this.editedIndex = -1
      this.loading = false
      this.editedItem = cloneDeep({
        ...BANNER_SCHEMA,
        company: {
          id: +this.currentCompanyId,
        },
      })
      this.file = null
      this.imageSource = ''
      this.clickedBannerId = null
      this.isGlobal = false
      this.$v.$reset()
    },

    close() {
      this.reset()
      this.dialog = false
    },

    async editItem(item) {
      this.editedIndex = item.id
      this.loading = true
      try {
        await this.loadSelectedMediaResource({ companyId: this.currentCompanyId, resourceId: this.editedIndex })
        this.editedItem = {
          ...cloneDeep(this.mediaResource),
          file_data: null,
        }
        this.isGlobal = !this.editedItem.company
        this.imageSource = `${this.baseURL}/uploads/media/${this.editedItem.file_name}`
      } finally {
        this.loading = false
      }
      this.dialog = true
    },

    deleteItem(item) {
      this.clickedBannerId = item.id
      this.dialogDelete = true
    },

    async save() {
      this.$v.$touch()
      if (this.$v.$anyError) return
      this.loading = true
      let payload = {
        companyId: this.currentCompanyId,
      }
      if (this.editedIndex === -1) {
        payload = {
          ...payload,
          newMediaResource: omit(this.editedItem, 'id'),
        }
      } else {
        payload = {
          ...payload,
          resourceId: this.editedItem.id,
          updatedMediaResource: omit(this.editedItem, ['id']),
        }
      }
      try {
        this.editedIndex === -1 ? await this.createMediaResource(payload) : await this.updateSelectedMediaResource(payload)
      } finally {
        this.close()
        await this.paginateTo()
      }
    },

    closeDelete() {
      this.dialogDelete = false
      this.clickedBannerId = null
      this.editedIndex = -1
    },

    async deleteBannerConfirm() {
      this.loading = true
      try {
        await this.deleteSelectedMediaResource({ companyId: this.currentCompanyId, resourceId: this.clickedBannerId })
      } finally {
        this.loading = false
        this.closeDelete()
        await this.paginateTo(true)
      }
    },
  },
}
</script>
